var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Main',
        },
        {
          title: 'Widgets',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" We used Apex Charts and customized for Yoda ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('line-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('column-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('area-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('scatter-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('bar-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('heatmap-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('radar-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('candlestick-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('donut-chart')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12","xl":"6"}},[_c('radialbar-chart')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }